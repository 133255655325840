export function filterAllowedEnvironments(environments,userId){
    if(environments && environments.length>0){
        return environments.filter(x=> !x.accessType || x.accessType==='public_access' || (x.accessType==='protected_access' && isValidUser(x.allowedUsers,userId)))
    }
    return environments
}

function isValidUser(users,userId){
    if(users && users.length>0){
        if(users.filter(x=>x.id===userId) && users.filter(x=>x.id===userId).length>0){
            return true
        }
    }
    return false
}