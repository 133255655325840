import React from "react";
import { useSelector } from "react-redux";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import { faFolder } from "@fortawesome/free-regular-svg-icons";

import { EntityDataRepository } from "../EntityDataRepository";

export function ProjectDataRepository(props) {
  const user = useSelector((store) => store.user);

  //Pasamos por props los iconos de CellTemplate
  return (
    <>
      <EntityDataRepository
        user={user}
        iconType1={faFolder} //Pasamos por props los iconos de CellTemplate
        iconType2={faProjectDiagram}
        actions={props.actions}
        entityName={props.entityName}
        selectedPrivateEntityId = {props.selectedProjectId}
      />
    </>
  );
}
