import _ from 'lodash';
import {FETCH_RULES_DATA_REPOSITORY,
    FETCH_RULE_DATA_REPOSITORY,
    EDIT_RULES_DATA_REPOSITORY,
    DELETE_RULES_DATA_REPOSITORY,
    CREATE_RULES_DATA_REPOSITORY
    } from '../actions/types'


export default (state={},action) => {
    switch(action.type){
        case FETCH_RULES_DATA_REPOSITORY:
            return {..._.mapKeys(action.payload,'_id')}
        case FETCH_RULE_DATA_REPOSITORY:
            return {...state,[action.payload._id]: action.payload};
         case CREATE_RULES_DATA_REPOSITORY:
            return {...state, [action.payload._id]: action.payload};
        case DELETE_RULES_DATA_REPOSITORY:
            return _.omit(state, action.payload)
        case EDIT_RULES_DATA_REPOSITORY:
            return {...state, [action.payload._id]: action.payload};
        default:
            return state;
        
    }
}