import React from "react";
import { useSelector } from "react-redux";
import {
    faFilter,
  } from "@fortawesome/free-solid-svg-icons"; 
  import { faFolder } from "@fortawesome/free-regular-svg-icons";

import { EntityDataRepository } from "../EntityDataRepository"


export function RuleDataRepository(props) {
  const user = useSelector((store) => store.user);

  //Pasamos por props los iconos de CellTemplate
  return (
    <>
       <EntityDataRepository  user={user} iconType1={faFolder} iconType2={faFilter}  actions={props.actions} entityName={props.entityName}/>
    </>
  );
}
