export function isProjectSimulable(project){
    if(project && project.qualityPoints && project.qualityPoints.length>0){
        for(var i = 0;i<project.qualityPoints.length;i++){
            let qualityPoint = project.qualityPoints[i]
            if(qualityPoint && qualityPoint.datasources && qualityPoint.datasources.length>0){
                for(var j=0;j<qualityPoint.datasources.length;j++){
                    let datasource = qualityPoint.datasources[j]
                    if(datasource && datasource.rules && datasource.rules.length>0){
                        return true
                    }
                }
            }
        }
    }
    return false
}

export function isQualityPointSimulable(qualityPoint){
           if(qualityPoint && qualityPoint.datasources && qualityPoint.datasources.length>0){
                for(var j=0;j<qualityPoint.datasources.length;j++){
                    let datasource = qualityPoint.datasources[j]
                    if(datasource && datasource.rules && datasource.rules.length>0){
                        return true
                    }
                }
          
    }
    return false
}

export function isProjectRecommendable(project){
    if(project && project.qualityPoints && project.qualityPoints.length>0){
        return true
    }
    return false
}

export function isProjectExportable(project){
    return isProjectSimulable(project)
}