import axios from 'axios';
import {API_MS_URL} from './../Constants'

export default axios.create(
    {
        baseURL: API_MS_URL
        //baseURL: "http://localhost:18092//"
    }
);


//"http://localhost:38081//"

//18092