export function isStreamProject(project){
    if(project === undefined){
        return false
    }
    if(project.qualityPoints===undefined || project.qualityPoints===null){
        return false
    }
    if(project.qualityPoints.filter(x=>x.runningType==='streaming_lambda')===undefined){
        return false
    }
    else if(project.qualityPoints.filter(x=>x.runningType==='streaming_lambda').length>0){
        return true
    }
    return false
}