import axios from 'axios';
import {API_MS_URL,ACCESS_TOKEN} from '../../../Constants'

export default axios.create(
    {
        baseURL: API_MS_URL
    }
);

export const axiosHeader =() =>{
    const headersA = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem(ACCESS_TOKEN),
       
    }
    
    return headersA
}

