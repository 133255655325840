import React from "react";
import { CircularProgress, Typography } from '@material-ui/core';



export default function LoadingComponent(props) {


    return (

        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress size={50} style={{ margin: '40px' }}/>
            <Typography variant='subtitle2' style={{ marginLeft: '10px'}}>Fetching rules</Typography>
            <Typography variant='caption' style={{ marginTop: '10px'}}>Depending on the file size the recommender may take some time. </Typography>
            <Typography variant='caption'>If you close this Dialog we will notify you when the recommender output is ready</Typography>
        </div>
    )
}
