import {FETCH_ERROR
    } from '../actions/types'


export default (state={},action) => {
    switch(action.type){
        case FETCH_ERROR:
        return action.payload;
        default:
            return state;
        
    }
}