import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@material-ui/core/styles";
import { EnhancedTable } from "./EnhancedTable";
import Moment from "react-moment";
import { Tooltip, Button, FormControlLabel, Checkbox } from "@material-ui/core";
import {clickOnNotification} from "./NotificationsHelper"

export function AllNotifications(props) {
  const dispatch = useDispatch();
  // const classes = useStyles();
  const theme = useTheme();
  const user = useSelector((store) => store.user);
  const allYourNotifications = useSelector((store) =>
    Object.values(store.allNotifications)
  );

  const [checked, setChecked] = React.useState(false);
  
  const printDetail = (notification) => {
    return <> 
      <Tooltip title="Go to detail">
        <Button
          style={{
            color: "grey",
            width: "5px",
            marginRight: "-10px",
            marginLeft: "5px",
          }}
          onClick={() =>
            clickOnNotification(
              notification,
              props.history,
              user,
              dispatch
            )
          }
        >
          <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
        </Button>
      </Tooltip>
    </>
  }

  const printDate = (creationDate) => {
     return <Moment format="DD/MM/YYYY">
        {creationDate}
      </Moment>
  }
  
  const printRead = (read) => {
    if (read === true) {
      return <FontAwesomeIcon icon={faEye} style={{ color: "grey" }} />;
    } else {
      return <FontAwesomeIcon icon={faEyeSlash} style={{ color: "4697c4" }} />;
    }
  }

  const headCells = [
    { id: 'longDescription', label: 'Description' },
    { id: 'creationDate', printComponent: printDate, label: 'Date',  sortable: true },
    { id: 'read', printComponent: printRead, label: '' },
    { id: 'viewDetail', printComponent: printDetail, label: 'View detail', needParent: true }
  ];
  
  return ( <>
    <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={() => setChecked((checked) => !checked)}
            name="checked"
            color="primary"
          />
          
        }
        style={theme.notifications.showUnreadCheck}
        label="Show unread notifications only"
      />
    <EnhancedTable 
      rows={allYourNotifications.filter((notification) => checked ? notification.read === false : true)} 
      headCells={headCells}

    />
  </>
  );
}
