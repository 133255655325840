import _ from 'lodash';
import {FETCH_SIMULATIONS,DELETE_SIMULATIONS
    } from '../actions/types'


export default (state={},action) => {
   
    switch(action.type){
        
        case FETCH_SIMULATIONS:
            return {..._.mapKeys(action.payload,'_id')}
        case DELETE_SIMULATIONS:
            return  _.omit(state, action.payload)
           
        default:
            return state;
        
    }
}