import React from 'react'
import AqtivaDialog from '../../../../common/AqtivaDialog'
import {Typography} from '@material-ui/core'
import { faHubspot } from '@fortawesome/free-brands-svg-icons'

export default function DeployDialogStreamDeploymentType(props) {

    function simpleDeployment(){
        props.simpleDeployment()
        props.setVisible(false)
    }

    function completeDeployment(){
        props.fullDeployment()
        props.setVisible(false)
    }

return (
    <AqtivaDialog visible={props.visible}
    maxWidth = {'lg'}
    title={'Choose Deployment Type'}
    titleIcon={faHubspot}
    confirmText={'Simple Deployment'}
    cancelText={'Full Deployment'}
    confirmCallback={event=>simpleDeployment()}
    cancelCallback={event=>completeDeployment()}
    >
        
        <div>
            <Typography variant='subtitle2'>
                Simple Deployment
            </Typography>
        </div>
        <div>
            <Typography variant='caption' style={{marginLeft:'10px'}}>
                Only update configuration. Use this option if the streams are not changed
            </Typography>
        </div>
        <div>
            <Typography variant='subtitle2'>
                Full Deployment
            </Typography>
        </div>
        <div>
            <Typography variant='caption' style={{marginLeft:'10px'}}>
                Delete if exist and deploy brand new Lambda Function
            </Typography>
        </div>
        
        </AqtivaDialog>
)
}