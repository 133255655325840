import _ from 'lodash';

import {
  FETCH_UNREAD_NOTIFICATIONS,
  DELETE_NOTIFICATIONS,
  ADD_NOTIFICATION
} from "../actions/types";



export default (state={},action) => {
    switch(action.type){
        case FETCH_UNREAD_NOTIFICATIONS:
            return {..._.mapKeys(action.payload,'_id')}
        case DELETE_NOTIFICATIONS:
            return _.omit(state, action.payload)
        case ADD_NOTIFICATION:
            return {...state, [action.payload._id]: action.payload};
        default:
            return state;
        
    }
}