import React from "react";
import {CircularProgress} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTable} from '@fortawesome/free-solid-svg-icons'
export function LoadingComponent(props) {
    if(props.noData &&props.noData===true){
        return(
            <>
            <section style={{textAlign:'center', border:'0px solid ', borderRadius:'20px',  width:'300px' , paddingTop:'20px', paddingBottom:'20px', paddingRight:'10px', paddingLeft:'10px', display:'flex', flexDirection:'column', justifyContent:'center', margin:'auto'}}>
            <p style={{paddingBottom:'10px', fontWeight:'bold', color:'#606060'}}><span style={{display:'block'}}>No Data found</span></p>
            <section style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
            <FontAwesomeIcon icon={faTable} style={{marginRight:'8', alignSelf:'center', color:'#66B2FF'}}/>
            </section>
            </section>
            </> 
    
    
        )   
    }
    else{
        return(
            <>
            <section style={{textAlign:'center', border:'0px solid ', borderRadius:'20px',  width:'300px' , paddingTop:'20px', paddingBottom:'20px', paddingRight:'10px', paddingLeft:'10px', display:'flex', flexDirection:'column', justifyContent:'center', margin:'auto'}}>
            <p style={{paddingBottom:'10px', fontWeight:'bold', color:'#606060'}}><span style={{display:'block'}}>Loading your data,</span> <span>please wait...</span></p>
            <section style={{display:'flex', flexDirection:'row', justifyContent:'center'}}>
            <FontAwesomeIcon icon={faTable} style={{marginRight:'8', alignSelf:'center', color:'#66B2FF'}}/>
            <CircularProgress size='1em' style={{alignSelf:'center'}} />
            </section>
            </section>
            </> 
    
    
        )   
    }
    
}