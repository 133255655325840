import ReactGA from 'react-ga';


export default function (category, action){
    ReactGA.event({
        category: category,
        action: action
      })
}
    
  