import {UPDATE_SIMULATION
    } from '../actions/types'


export default (state={},action) => {
    
    switch(action.type){
        case UPDATE_SIMULATION:
        return {...state, [action.payload._id]: action.payload};
        default:
            return state;
        
    }
}