export function filterSharedRepository(data, userId) {
  if (!data || !data.node) {
    return data;
  }

  var nodeArray = [];

  for (var i = 0; i < data.node.length; i++) {
    var nodeBase = data.node[i];
    if (
      nodeBase.data.private === "Public" ||
      (nodeBase.data.private === "Private" && nodeBase.data.author === userId)
    ) {
      //we include it
      var nodeBaseFiltered = {
        key: nodeBase.key,
        data: nodeBase.data,
        children: getFilteredChildrenData(nodeBase.children, userId),
      };
      nodeArray.push(nodeBaseFiltered);
    }
  }

  var newData = { node: nodeArray }; //create a copy

  return newData;
}

function getFilteredChildrenData(data, userId) {
  var array = [];
  for (var i = 0; i < data.length; i++) {
    var nodeBase = data[i];
    if (
      nodeBase.data.private === "Public" ||
      (nodeBase.data.private === "Private" && nodeBase.data.author === userId)
    ) {
      //we include it
      array.push(nodeBase);
    }
  }
  return array;
}

export function parseEntityDataRepositoryToTree(entityDataRepository) {
  if (entityDataRepository && entityDataRepository.length > 0) {
    const newArray = entityDataRepository
      .filter((element) => element !== "")
      .map((data) =>
        getChildren(
          data,
          "Folder",
          data.entityDataFolders,
          data.qualityEntityRepoVirtualDTOS || data.qualityEntityRepoDTOS
        )
      );
    return { node: newArray };
  }

  return {
    node: [
      {
        key: "not-found",
        data: {
          name: "No records found",
        },
      },
    ],
  };
}

function getChildren(data, type, childrenFolders, childrenFiles) {
  const ownChildrenFolders = childrenFolders
    ? childrenFolders.filter((child) => data._id === child.parentId)
    : [];

  const ownChildrenFiles = childrenFiles
    ? childrenFiles.filter((child) => data._id === child.parentFolderId)
    : [];
  return {
    key: data._id,
    data: {
      name: data.name
        ? data.name
        : data.rootFolder && data.rootFolder.name
        ? data.rootFolder.name
        : "Name not found",
      date: data.creationDate ? data.creationDate : "No date",
      type: type,
      author: data.ownerId,
      private:
        data.userPermissions && data.userPermissions.typePermissions
          ? data.userPermissions.typePermissions
          : "Private",
      share: "yes",
      projectId: data.entityId,
    },
    children: [
      ...ownChildrenFolders.map((child) =>
        getChildren(child, "Folder", childrenFolders, childrenFiles)
      ),
      ...ownChildrenFiles.map((child) => getChildren(child, "Files")),
    ],
  };
}
