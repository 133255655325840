import _ from 'lodash';
import {FETCH_DATASETS_BATCH_DATA_REPOSITORY,
    FETCH_DATASET_BATCH_DATA_REPOSITORY,
    EDIT_DATASETS_BATCH_DATA_REPOSITORY,
    DELETE_DATASETS_BATCH_DATA_REPOSITORY,
    CREATE_DATASETS_BATCH_DATA_REPOSITORY
    } from '../actions/types'


export default (state={},action) => {
    switch(action.type){
        case FETCH_DATASETS_BATCH_DATA_REPOSITORY:
            return {..._.mapKeys(action.payload,'_id')}
        case FETCH_DATASET_BATCH_DATA_REPOSITORY:
            return {...state,[action.payload._id]: action.payload};
         case CREATE_DATASETS_BATCH_DATA_REPOSITORY:
            return {...state, [action.payload._id]: action.payload};
        case DELETE_DATASETS_BATCH_DATA_REPOSITORY:
            return _.omit(state, action.payload)
        case EDIT_DATASETS_BATCH_DATA_REPOSITORY:
            return {...state, [action.payload._id]: action.payload};
        default:
            return state;
        
    }
}