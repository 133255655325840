import React from "react";
import { StyledMenu, StyledMenuItem } from "../../../../common/CustomizedMenu";

import { ListItemIcon, ListItemText } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function RightClickMenu({ options, mouseState, handleClose }) {
  const optionsItems = options.map((option) => {
    return (
      <StyledMenuItem key={option.name} onClick={option.onClick}>
        <ListItemIcon>
          <FontAwesomeIcon
            icon={option.icon}
            style={{ fontSize: 14, marginLeft: "15%" }}
          />
        </ListItemIcon>
        <ListItemText primary={option.name} />
      </StyledMenuItem>
    );
  });

  return (
    <>
      <StyledMenu
        keepMounted
        open={mouseState.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseState.mouseY !== null && mouseState.mouseX !== null
            ? { top: mouseState.mouseY, left: mouseState.mouseX }
            : undefined
        }
      >
        {optionsItems}
      </StyledMenu>
    </>
  );
}
