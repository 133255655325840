
export function getPathArray(page){
    if(page === 'ProjectDetail'){
        return [
            {
                route:'/home',
                text: 'Projects'
            }
        ]
    }
    if(page === 'Consumption'){
        return [
            
        ]
    }
    if(page === 'Governance'){
        return [
            
        ]
    }
    if(page === 'GovernanceDetail'){
        return [
            {
                route:`/${process.env.REACT_APP_PREFIX}/governance`,
                text: 'KPI Governance'
            } 
        ]
    }
    if(page === 'ProjectDetail'){
        return [
            {
                route:'/home',
                text: 'Projects'
            }
        ]
    }
    
    if(page === 'QualityDatasetRepositoryPage'){
        return [
            {
                route:'/home',
                text: 'Datasets'
            }
        ]
    }
    if(page === 'QualitySimulationRepositoryPage'){
        return [
            {
                route:'/home',
                text: 'Simulations'
            }
        ]
    }
   
    return []
}