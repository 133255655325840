import React from 'react';
import {Drawer,IconButton,Divider,List,ListItem,ListItemIcon,ListItemText,Typography} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import * as menuHelper from './MenuHelper'
import {USER} from './../../Constants'
import HomeIcon from '@material-ui/icons/Home'
import BuildIcon from '@material-ui/icons/Build'
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarehouse, faPlayCircle, faChartPie } from '@fortawesome/free-solid-svg-icons'
import { faTools } from '@fortawesome/free-solid-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'

import { faQuinscape } from '@fortawesome/free-brands-svg-icons'
import { faChartBar } from '@fortawesome/free-regular-svg-icons'
import { faToolbox } from '@fortawesome/free-solid-svg-icons'


import trackEvent from './../../trackEvent'


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    color: "#FFFFFF",
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    color: "#FFFFFF",
    backgroundColor: '#00000000',
    flexShrink: 0,
  },
  drawerPaper: {
    color: "#FFFFFF",
    backgroundColor: "#212121 !important",
    width: drawerWidth,
  },
  drawerHeader: {
    backgroundColor: '#00000000',
    color: "#FFFFFF",
        display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    color: "#FFFFFF",
    backgroundColor: "#212121 !important",
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function getSidebarIcon(label){

  if(label === 'Home'){
   return <HomeIcon fontSize="small" style={{color:'#FFFFFF'}} />
  }
  if(label === 'Simulations'){
    return <FontAwesomeIcon icon={faPlayCircle} style={{color:'#FFFFFF', fontSize:15}} />
   }
   if(label === 'Datasets'){
    return <FontAwesomeIcon icon={faDatabase} style={{color:'#FFFFFF', fontSize:15}} />
  }
  if(label === 'Admin'){
  return <BuildIcon fontSize="small" style={{color:'#FFFFFF'}} />
  }
 if(label === 'Licenses'){
  return <FilterNoneIcon fontSize="small" style={{color:'#FFFFFF'}} />
}
 if(label === 'Infrastructure'){
  return <DeveloperBoardIcon fontSize="small" style={{color:'#FFFFFF'}} />
}
if(label === 'Repository'){
 return <FontAwesomeIcon icon={faWarehouse} style={{color:'#FFFFFF', fontSize:15}} />
}
if(label === 'Configuration'){
  return <FontAwesomeIcon icon={faTools} style={{color:'#FFFFFF', fontSize:15}} />
 }
 if(label === 'Quality'){
  return <FontAwesomeIcon icon={faQuinscape} style={{color:'#FFFFFF', fontSize:17}} />
 }
 if(label === 'Dashboard'){
  return <FontAwesomeIcon icon={faChartBar} style={{color:'#FFFFFF', fontSize:17}} />
 }
 if(label === 'Governance'){
  return <FontAwesomeIcon icon={faChartPie} style={{color:'#FFFFFF', fontSize:17}} />
 }
 if(label === 'Root Management'){
  return <FontAwesomeIcon icon={faToolbox} style={{color:'#FFFFFF', fontSize:17}} />
 }
return <div></div>
}

function SidebarItem({ label, items, depthStep = 10, depth = 0, route='/',setRedirect=undefined,setRedirectUrl = undefined, ...rest }) {
 
    return (
      <>
        <ListItem button dense {...rest} onClick={event =>{
          setRedirect(true)
          setRedirectUrl(route)
        }}>
          <ListItemIcon style={{ paddingLeft: depth * depthStep*2 }}>
          
            {getSidebarIcon(label)}
          </ListItemIcon>
          <ListItemText style={{ paddingLeft: depth * 0 }}>
            <Typography variant={depth===0 ? 'subtitle1' : 'subtitle2'}>{label}</Typography>
          </ListItemText>
        </ListItem>
        {Array.isArray(items) ? (
          <List disablePadding dense>
            {items.map((subItem) => (
              <SidebarItem
                key={subItem.name}
                depth={depth + 1}
                depthStep={depthStep}
                route={subItem.route}
                setRedirect = {setRedirect}
                setRedirectUrl={setRedirectUrl}
                {...subItem}
              />
            ))}
          </List>
        ) : null}
      </>
    )
  }

export default function Sidebar(props){
    const classes = useStyles();
    const theme = useTheme();
    //const items = props.items
    var userStr = localStorage.getItem(USER);
    var user = {}
    try {
      user =(JSON.parse(userStr));
  } catch (e) {
      if (e instanceof SyntaxError) {
          console.error(e);
      } else {
          console.error(e);
      }
  }

    const items = menuHelper.generateSidebarMenu(user, props.localPath)
    const[redirect,setRedirect] = React.useState(false)
    const [redirectUrl,setRedirectUrl] = React.useState(undefined)

    if(redirect===true  && props.localPath!==redirectUrl) {
      trackEvent('SideMenu', props.localPath)
      return(
         <Redirect to={{
          pathname: redirectUrl,
          
      }}/>
      )
    }
    
    return(


    
       
            <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={props.open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={props.handleDrawerClose} style={{color: "#FFFFFF",}}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List disablePadding dense>
        {items.map((sidebarItem, index) => (
          <SidebarItem
            key={`${sidebarItem.name}${index}`}
            depthStep={props.depthStep}
            depth={props.depth}
            route={sidebarItem.route}
            setRedirect = {setRedirect}
            setRedirectUrl={setRedirectUrl}
            {...sidebarItem}
          />
        ))}
      </List>
      </Drawer>
    )
}

