import {FETCH_CONFIGURATION,
        UPDATE_CONFIGURATION
    } from '../actions/types'


export default (state={},action) => {
    
    switch(action.type){
        case FETCH_CONFIGURATION:
            return action.payload;
        case UPDATE_CONFIGURATION:
            return action.payload;
        default:
            return state;
        
    }
}