
const rulesAllowedRecommender = [
    'null_rule',
    "duplicates_rule",
    "values_rule",
    "numeric_range_rule",
    "values_rule",
    "reg_exp_rule"
]

export function filterRecommenderData(data){
   
    data.columns =data.columns.map(x=>{return {column:x.column, rules: x.rules.filter(x=>rulesAllowedRecommender.includes(x.rule))}})
    return data
}