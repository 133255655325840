import {FETCH_LICENSE,
    UPDATE_LICENSE
    } from '../actions/types'


export default (state={},action) => {
    
    switch(action.type){
        case FETCH_LICENSE:
            return action.payload;
        case UPDATE_LICENSE:
            return action.payload;
        default:
            return state;
        
    }
}