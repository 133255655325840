import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'



export const useTracking = (
  trackingId = process.env.REACT_APP_GA_MEASUREMENT_ID
) => {
  const { listen } = useHistory()

  useEffect(() => {
    const unlisten = listen((location) => {
      if (!window.gtag) return
      if (!trackingId) {
        console.log(
          'Tracking not enabled, as `trackingId` was not given and there is no `REACT_APP_GA_MEASUREMENT_ID`.'
        )
        return
      }
       var loc = location.pathname
      if(loc.includes(`${process.env.REACT_APP_PREFIX}/project`)){
        loc = `/${process.env.REACT_APP_PREFIX}/project`
      }
      if(loc.includes(`${process.env.REACT_APP_PREFIX}/dashboard`)){
        loc = `/${process.env.REACT_APP_PREFIX}/dashboard`
      }
      window.gtag('config', trackingId, { page_path: loc })
    })

    return unlisten
  }, [trackingId, listen])
}