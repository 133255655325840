//tiene que encontrar el objeto con la clave value, de forma anidada
export function findValueInTreeDataAndOptionalCallback(
  treeData,
  value,
  callback
) {
  //tree data tiene como elemento origen root
  //dentro de root tiene un array de objetos

  if (!treeData || !treeData.node) {
    return undefined;
  }

  const nodes = treeData.node;

  for (var i = 0; i < nodes.length; i++) {
    //comprobamos si el nodo cumple la condicion
    if (nodes[i].key === value) {
      if (callback) {
        callback(nodes, i);
      }

      return nodes[i];
    } else {
      //tengo que buscar en los hijos
      var returnChildrenAndPosition = findValueInChildren(
        nodes[i].children,
        value
      );

      if (returnChildrenAndPosition) {
        const [returnChildren, position] = returnChildrenAndPosition;
        if (callback) {
          callback(returnChildren, position);
        }

        return returnChildren[position];
      }
    }
  }
  return undefined;
}

function findValueInChildren(children, value) {
  if (!children) {
    return undefined;
  } else {
    for (var i = 0; i < children.length; i++) {
      if (children[i].key === value) {
        return [children, i];
      } else {
        var returnChildrenAndPosition = findValueInChildren(
          children[i].children,
          value
        );
        if (returnChildrenAndPosition) {
          return returnChildrenAndPosition;
        }
      }
    }
  }
  return undefined;
}
