
import quality from '../apis/quality';
import {
    FETCH_LICENSE} from './types'
    import {axiosHeader,dispatchInfo, dispatchError} from '../components/common/axios/axiosHelper';


export const fetchLicense = (userId) =>{
    return async function(dispatch, getState){

    await quality.get(`/license/userId/${userId}`,{
        headers: axiosHeader()
      }).then(function (response) {
        dispatch( {
            type : FETCH_LICENSE,
            payload: response.data
        });
    })
    .catch(function (error) {
        dispatchError(dispatch,error)
        
        
      });
    }
    
};



export const fetchAll = () => async (dispatch,getState) => {
    
    var returnValue = []   
   await quality.get(`/license/all/`,{
       headers: axiosHeader()
     }).then(function (response) {
           
           returnValue = response.data
           dispatchInfo(dispatch,`All licenses fetched`)
           
       })
       .catch(function (error) {
        dispatchError(dispatch,error)
           
           returnValue = {result:'ko',error:error}
         });
   
   
  return returnValue
       }





       export const createLicense = (license) => async (dispatch,getState) => {
        var returnValue = []   
        if(license.apiKey){
          
          await quality.post(`/license/new/`,license,{
              headers: axiosHeader()
            }).then(function (response) {
                  
                  returnValue = response.data
                  dispatchInfo(dispatch,`License created`)
                  
              })
              .catch(function (error) {
               dispatchError(dispatch,error)
                  
                  returnValue = {result:'ko',error:error}
                });
          
          
         return returnValue
        }
        else{
          dispatchError(dispatch,"No apiKey")
                  
                  returnValue = {result:'ko',error:"No apiKey"}
               
        }
       
           }



       export const updateLicense = (license) => async (dispatch,getState) => {
    
        var returnValue = []   
       await quality.post(`/license/new/`,license,{
           headers: axiosHeader()
         }).then(function (response) {
               
               returnValue = response.data
               dispatchInfo(dispatch,`License updated`)
               
           })
           .catch(function (error) {
            dispatchError(dispatch,error)
               
               returnValue = {result:'ko',error:error}
             });
       
       
      return returnValue
           }