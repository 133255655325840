export const chartKPIdata1 = [
    {
        name: 'Page A', value: 80, remainValue: 20
      }
    ];
export const chartKPIdata2 = [
  {
      name: 'Page A', value: 60, remainValue: 40
    }
  ];
export const chartKPIdata3 = [
    {
        name: 'Page A', value: 40, remainValue: 60
      }
    ];
export const chartKPIdata4 = [
      {
          name: 'Page A', value: 90, remainValue: 10
        }
      ];

export const chartRuleStatusByNameData1 = [

  {name:'check between loads', value: false},
  {name:'ñ à â á', value: true},
  {name:'Esquema', value: true},
  {name:'Nulos', value: false},
  {name:'Regex DNI', value: true},
  {name:'Regex Telfn', value : true}

]

export const rules = [

  {name:'checkafaefeagegagaegeagaecheckafae', state: "OK"},
  {name:'check between ', state: "ok"},
  {name:'check between loads', state: "ok"},
  {name:'check between loads', state: "ok"},
  {name:'check between loads', state: "ok"},
{name:'check between loads', state: "OK"},
  {name:'check between loads', state: "ok"},
  {name:'check between loads', state: "ok"},

]

export const ruleNamePercentsDAO = [

  {ruleName:'Esquema', percentOKRuleName: 30},
  {ruleName:'Nulls', percentOKRuleName: 70},
  {ruleName:'Regex DNI', percentOKRuleName: 80},
  {ruleName:'check between loads', percentOKRuleName: 100},
  {ruleName:'Nulls', percentOKRuleName: 70},
  {ruleName:'Regex DNI', percentOKRuleName: 80},
  {ruleName:'check between loads', percentOKRuleName: 100},
  {ruleName:'Nulls', percentOKRuleName: 70},
  {ruleName:'Regex DNI', percentOKRuleName: 80},
  {ruleName:'check between loads', percentOKRuleName: 100},
  {ruleName:'Nulls', percentOKRuleName: 70},
  {ruleName:'Regex DNI', percentOKRuleName: 80},
  {ruleName:'check between loads', percentOKRuleName: 100},

] 

export const PieChartData = [
  { name: 'Validity', value: 10 },
  { name: 'Veracity', value: 30 },
  { name: 'Integrity', value: 15 },
  { name: 'Unicity', value: 15 },
  { name: 'Timeliness', value: 10 },
  { name: 'Format', value: 20 }
];
export const PaddedPieChartData = [
  { name: 'A1', value: 90 }
];
export const dataLineChart = [
  {
    "date": "05/01/2020 17:26:17",
    "ok": 40,
    "ko": 80
    },
  {
    "date": "05/01/2020 18:26:17",
    "ok": 60,
    "ko": 90,
  },
  {
    "date": "05/01/2020 19:26:17",
    "ok": 52,
    "ko": 90,
  },
  {
    "date": "05/01/2020 20:26:17",
    "ok": 90,
    "ko": 60,
  },
  {
    "date": "05/01/2020 21:26:17",
    "ok": 66,
    "ko":30,
  },
  {
    "date": "05/01/2020 22:26:17",
    "ok": 60,
    "ko": 20,
  },
  {
    "date": "05/01/2020 23:26:17",
    "ok": 20,
    "ko": 50,
  }
]
export const MongoDataExample = {
  "_id" : "5ece4ee255dca0691ba452d1",
  "environment" : "On-premise",
  "qualityPoints" : [
      {
          "_id" : "qq422x",
          "datasources" : [
              {
                  "_id" : "p9met8",
                  "dataset" : {
                      "_id" : "5ee28b10f3e7484581085fb6",
                      "columns" : [
                          {
                              "position" : 0,
                              "name" : "customercode",
                              "type" : "String"
                          }
                      ],
                      "userId" : "5d829501ee51b877f859a29b",
                      "name" : "dddd",
                      "creationDate" : "2020-06-11T19:50:40.025Z",
                      "updateDate" : "2020-06-11T19:50:40.025Z"
                  },
                  "rules" : [],
                  "folders" : [],
                  "userId" : "5d829501ee51b877f859a29b",
                  "name" : "ds1",
                  "creationDate" : "2020-06-11T19:50:50.282Z",
                  "updateDate" : "2020-06-11T19:50:50.282Z",
                  "active" : true,
                  "errorTh" : {
                      "type" : "number",
                      "value" : 100.0,
                      "action" : "continue_action"
                  },
                  "warningTh" : {
                      "type" : "number",
                      "value" : 10.0,
                      "action" : "continue_action"
                  },
                  "controlModeType" : "INFO",
                  "wrongRegisterActionType" : "discard_action"
              }
          ],
          "userId" : "5d829501ee51b877f859a29b",
          "name" : "QP1",
          "creationDate" : "2020-05-29T12:04:39.469Z",
          "updateDate" : "2020-06-12T16:41:04.337Z",
          "active" : true,
          "errorTh" : {
              "type" : "number",
              "value" : 10.0,
              "action" : "continue_action"
          },
          "warningTh" : {
              "type" : "number",
              "value" : 5.0,
              "action" : "continue_action"
          },
          "controlModeType" : "INFO",
          "wrongRegisterActionType" : "discard_action"
      },
      {
        "_id" : "qq422x2",
        "datasources" : [
            {
                "_id" : "p9met82",
                "dataset" : {
                    "_id" : "5ee28b10f3e7484581085fb6",
                    "columns" : [
                        {
                            "position" : 0,
                            "name" : "customercode",
                            "type" : "String"
                        }
                    ],
                    "userId" : "5d829501ee51b877f859a29b",
                    "name" : "dddd",
                    "creationDate" : "2020-06-11T19:50:40.025Z",
                    "updateDate" : "2020-06-11T19:50:40.025Z"
                },
                "rules" : [],
                "folders" : [],
                "userId" : "5d829501ee51b877f859a29b",
                "name" : "ds1",
                "creationDate" : "2020-06-11T19:50:50.282Z",
                "updateDate" : "2020-06-11T19:50:50.282Z",
                "active" : true,
                "errorTh" : {
                    "type" : "number",
                    "value" : 100.0,
                    "action" : "continue_action"
                },
                "warningTh" : {
                    "type" : "number",
                    "value" : 10.0,
                    "action" : "continue_action"
                },
                "controlModeType" : "INFO",
                "wrongRegisterActionType" : "discard_action"
            }
        ],
        "userId" : "5d829501ee51b877f859a29b",
        "name" : "QP2",
        "creationDate" : "2020-05-29T12:04:39.469Z",
        "updateDate" : "2020-06-12T16:41:04.337Z",
        "active" : true,
        "errorTh" : {
            "type" : "number",
            "value" : 10.0,
            "action" : "continue_action"
        },
        "warningTh" : {
            "type" : "number",
            "value" : 5.0,
            "action" : "continue_action"
        },
        "controlModeType" : "INFO",
        "wrongRegisterActionType" : "discard_action"
    }
  ],
  "userId" : "5d829501ee51b877f859a29b",
  "name" : "test2",
  "creationDate" : "2020-05-27T11:28:34.083Z",
  "updateDate" : "2020-06-12T17:50:10.098Z",
  "_class" : "com.matica.oluvion.service.model.project.QualityProject"
}
  