import axios from 'axios';
//import {basePath} from '../constants'
import {baseUrlLogin} from './../Constants'

export default axios.create(
    {
        baseURL: baseUrlLogin
        //baseURL: basePath
    }
);


