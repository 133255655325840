import React, { Component } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { styles, QualityTheme } from './../../style/CustomTheme'

class SelectOneValueWithError extends Component {
    
    /*recibimos por propiedades:
        Obligatorios:
            value: referencia donde se guardará el valor
            onChange: referencia a función onChange
            data: array con los datos recibidos, por defecto recibe objetos
        
        Opcionales:
            width: ancho del objeto
            fontSizeError: ancho del icono o fuentes 
            caption: el título del elemento
            idField: nombre del campo id del elemento en el data
            textField: campo del texto en los data
            error:boolean que indica si mostrar error
            errorLabel: mensaje a mostrar
    */
    constructor(props){
        super(props)
        this.state={
           width: props.width ? props.width : '170px',
           fontSizeError : props.fontSizeError ? props.fontSizeError : '10px',
           caption: props.caption ? props.caption : '',
           idField: props.idField ? props.idField : 'id',
           textField: props.textField ? props.textField : 'text',
           errorLabel: props.errorLabel ? props.errorLabel: 'Error in field'

        }
    }
    render(){
        const { classes } = this.props
    return(
        <div>
            <FormControl className={classes.formControl}>
                    <Typography variant='caption'>{this.props.caption}</Typography>
                    <Select
                        style={{ width: this.state.width }}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        inputProps={{
                            name: this.state.caption,
                            id: this.state.caption
                                                }}>
                            { this.props.data && this.props.data.map((value) =>
                                            <MenuItem key={value[this.state.idField]} value={value[this.state.textField]}>
                                                 <Typography>{value[this.state.textField]}</Typography>
                                            </MenuItem>
                                        )}
                            </Select>
                            {this.props.error && this.state.errorLabel &&
                            <div className="ui error message" 
                            style={{ width: this.state.width, fontSize: this.state.fontSizeError }}>
                            <div className="header" >{this.state.errorLabel}</div>
                            </div>}
                </FormControl>
    </div>
    );
     }
}


export default withStyles(styles(QualityTheme))(SelectOneValueWithError);